import React from 'react'
import { Link } from 'gatsby'
import YouTube from 'react-youtube'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { StaticHero, ImageViewer } from '/src/components/common'

const MillImageOne = () => {
  return <StaticImage 
    src="../../images/salisbury-1.png" 
    alt="Mill image one"
    width={520}
    height={420}
    placeholder="blurred"/>
}

const MillImageTwo = () => {
  return <StaticImage 
    src="../../images/salisbury-2.png" 
    alt="Mill image two"
    width={520}
    height={420}
    placeholder="blurred" />
}

const MillPage = () => {
  return (
    <Layout hero={<StaticHero title="Salisbury Mill">
      <StaticImage 
        src="../../images/salisbury-1.png" 
        alt="Hero image"
        width={1420}
        height={250}
        placeholder="blurred"
        layout="fixed"
        transformOptions={{
          cropFocus: 'center'
        }} />
    </StaticHero>} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <div className="mt-4 mb-4">
            <Link className="back-to-link" to="/mills">
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to mills
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs={10}>
          <Row>
            <Col xs={12}>
              <div className="title-container mb-5">
                <h1 className="title">Salisbury</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>Orrcon Steel's facility located at 121 Evans Road in Salisbury, Queensland is a key manufacturing and distribution centre for high-quality steel products in Australia. The facility produces a wide range of steel products, including structural tube, precision tube, and line pipe for use in various industries, such as construction, mining, and agriculture.</p>
              <p>The Salisbury facility is equipped with modern technology and state-of-the-art equipment, including a tube mill, slitting lines, and advanced quality control systems, to ensure the consistency and quality of the products produced. The facility employs around 160 people directly and contributes to the employment of many more in the region.</p>
              <p>Orrcon Steel is committed to maintaining the highest standards of safety and environmental sustainability at the Salisbury facility. The facility has implemented comprehensive safety programs, including regular safety audits and training programs for employees and contractors, to ensure a safe working environment for all. The facility also has initiatives in place to minimize its impact on the environment, including waste reduction and water conservation measures.</p>
              <p>In recent years, Orrcon Steel has made significant investments in modernizing and upgrading its Salisbury facility, including the installation of a new tube mill and slitting lines. This has increased the facility's production capacity and allowed it to produce a wider range of high-quality steel products.</p>
              <p>In summary, Orrcon Steel's Salisbury facility is a vital contributor to the Australian steel industry, producing high-quality products used in various industries. The facility's commitment to safety, environmental sustainability, and innovation ensures that its products are of the highest quality and its operations are efficient and effective.</p>
            </Col>
            <Col md={6}>
              <ImageViewer images={[
                <MillImageOne />,
                <MillImageTwo />
              ]} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-5">
        <Col xs={10}>
          <Row>
            <Col xs={12}>
              <div className="title-container mb-5">
                <h1 className="title">Mill Tour Video</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ul className="store-address-list">
                <li>
                  <label>Address</label>
                  <span>121 Evans Rd, Salisbury, QLD, 4107</span>
                </li>
                <li>
                  <label>Phone Number</label>
                  <span>1300 677 266</span>
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <YouTube videoId="UN-FhMpcI-8" title="The Salisbury site&#39;s 360-degree mill tour." />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default MillPage

export const Head = () => <title>Salisbury Mill | Orrcon Steel</title>
